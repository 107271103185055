
  .banner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
    position: relative; /* 讓圖片在容器內定位 */
  }
  
  .banner-container img {
    max-width: 80%;
    height: 100%;
    object-fit: fill;
    cursor: pointer;  /* 添加游標指示為手指，表示可以點擊 */
  }
  
  .custom-modal-body {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .custom-modal-body img {
    max-width: 100%;
    max-height: 100%;
  }
  

  .close-button {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 24px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: #101010;
    font-weight: bold;
  }

  @media (max-width: 767px) {
    .banner-container {
      height: 200px; /* 在手機上縮小高度 */
    }
    .banner-container img {
      max-height: 90%; /* 在手機上縮小圖片高度 */
      max-width: 100%;
    }
  }
