
.progress-circle-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress-circle {
  width: 50px;
  height: 50px;
}

.progress-circle-bar {
  fill: none;
  stroke: black;
  stroke-width: 5;
  stroke-dasharray: 283;
  stroke-dashoffset: 0;
  transform-origin: center;
  transition: stroke-dashoffset 0.3s ease;
}

.progress-background {
  fill: transparent;
  stroke: #e1d7d7;
  stroke-width: 5;
  stroke-dasharray: none;
}


.scroll-to-top {
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  cursor: pointer;
  transition: opacity 0.3s ease;
  z-index: 9998;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
}

.scroll-to-top svg {
  display: block;
  width: 10px;
  height: 10px;
  stroke: rgb(31, 37, 110);
  transition: all 0.1s ease;
}


.caroimg {
    object-fit: cover;
    object-position: center;
    min-height: 30%;
    width: 100%;
    overflow: hidden;
    border-radius: 5px 5px 5px 5px;
}
@media (max-width: 767px) {
  .caroimg {
    border-radius: 3px 3px 3px 3px;
    height: 230px;
  }
}

/* 媒體查詢，當螢幕寬度大於或等於 768px 時 */
@media (min-width: 768px) {
  .caroimg {
    height: 430px; 
  }
}

.des{
    min-height: 25vh;
    max-height: 430px;
    width: 49vh;
    background-color: rgba(66, 133, 199, 0.75);
    border: 1.8px solid #050505;
    border-radius: 5px 5px 5px 5px;
}

@media (max-width: 767px) {
  .des{
    min-height: 30vh;
    max-height: 70vh;
    width: 50vh;
    border: 1.2px solid #000000;
    border-radius: 5px 5px 5px 5px;
}
}


.card:hover {
    transition: .5s ease;
    opacity: 0.5;
}

.cardimg {
    height: 20rem;
    width: 100%;
    object-fit: contain;
    overflow: hidden;
    border-radius: 10px 10px 10px 10px;
}

.merchandise {
    text-align: center;
  }
  .product-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* 將商品集中排列 */
    gap: 20px;
  }
  
  .product-card {
    width: 170px;
    padding: 0px;
    text-align: center;
    border: 0.5px solid #141414; /* 加粗邊線 */
    border-radius: 15px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }

  @media screen and (max-width: 767px) {
    .product-card {
      width: calc(50% - 15px); /* 计算每个卡片的宽度为一半减去间距 */
    }
  }
  
  .product-card img {
    width: 140px; /* 商品圖片的寬度 */
    height: 150px; /* 商品圖片的高度 */
    object-position: center;
    width: 100%;
    border-radius: 15px 15px 0 0;
    margin-bottom: 10px;
    object-fit: fill;
  }
  
  .product-detail {
    margin-top: 20px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    text-align: center;
  }

  .banner-container {
    width: 100%; /* 確保容器佔據全部可用空間 */
    overflow: hidden; /* 這會防止圖片超出容器 */
}

  .banner-image {
    width: 100%;
    object-fit: cover;
    height: 20px;
  }

.custom-modal-body{
  width: 100%; /* 確保容器佔據全部可用空間 */
  overflow: hidden; 
}
.modal-image{
  width: 100%;
  height: auto; /* 這保證圖片高度按比例縮放 */
  object-fit: cover;
}

@keyframes circleShake {
  0% { transform: rotate(0deg); }
  25% { transform: rotate(-15deg); }
  50% { transform: rotate(15deg); }
  75% { transform: rotate(-15deg); }
  100% { transform: rotate(15deg); }
}

.hot-badge {
  position: absolute;
  top: 0;
  left: 0;
  background-color: red;
  color: white;
  padding: 5px 10px;
  font-weight: bold;
  z-index: 1;
  animation: circleShake 1s infinite;
}

.sale-label {
  background-color: orange;
  color: white;
  padding: 2px 5px;
  font-weight: bold;
  margin-left: 5px;

}

.product-card {
  position: relative; /* 使其內部的絕對定位元素相對於此元素定位 */
}
