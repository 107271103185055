

.form-container {
    background-color: rgba(241, 234, 234, 0.8);
    border-radius: 20px;
    padding: 20px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
}

.form-title {
    color: #333;
    margin-bottom: 10px;
}

/* TextField 的邊界顏色和大小 */
.form-text-field {
    border-color: #FF5733 !important;  /* 這是一個示例顏色，你可以更改它 */
  }
  
  /* 提交按鈕的樣式 */
  .form-submit-btn {
    border: 2px solid #4A90E2 !important;
    background-color: #4A90E2 !important; 
    color: white !important;
    padding: 10px 15px;  /* 为按钮添加适量的内边距 */
    border-radius: 4px;  /* 给按钮添加一点圆角 */
    font-size: 16px;     /* 适量的字体大小 */
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out; /* 添加一个 transform 过渡效果 */
    cursor: pointer;     /* 鼠标悬停时呈现手指样式，增加用户友好性 */
    display: inline-block;
    font-size: 16px;    /* 調整字體大小 */
    width: 150px; 
}

.form-submit-btn:hover {
    background-color: #357ABD;  /* 使用较深的蓝色为悬停状态 */
    transform: scale(1.05);     /* 添加一个轻微的缩放效果为悬停状态 */
}
  
  /* 中間分隔線的樣式 */
  .form-custom-divider {
    height: 80%;  /* 你可以根據需求調整這個 */
    background-color: #E84020;
  }
  
  .question-description {
    font-size: medium; /* 或者使用具体的值，如 18px */
    font-weight: bold;
}

.collapsible-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.collapsible-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0;
}

.question-description {
  font-size: medium;
  font-weight: bold;
}

.collapsible-img-wrapper {
  height: 240px; 
  width: 380px;
  transition: max-height 0.5s ease-in-out;
  margin: 8px auto 0; 
  border-radius: 15px;
  overflow: hidden;
}

.collapsible-img-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.collapsible-icon {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border: 2px solid black;
  border-radius: 50%;
}

/* Container Style */
.bank-info-container {
  padding: 12px;
  border: 1px solid #000;
  border-radius: 5px;
  background-color: #b8dee9;
  margin: 5px;
}

/* Typography Style */
.bank-info-container .account-info {
  line-height: 1.5;
  font-size: 16px;
  font-weight: bold;
  color: #000;
}
