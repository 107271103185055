
.floating-icon-container {
    position: fixed;
    bottom: 80px;
    right: 20px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #ffffff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    z-index: 9999;
    transition: bottom 0.3s ease; /* 加入bottom屬性的過渡效果 */
    cursor: pointer; /* 讓圖示變成指標樣式，表示可以點擊 */
  }
  
  .floating-icon-container img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
  