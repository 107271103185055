.announce-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px; /* 您可以根據需要調整高度 */
  background-color: #2b365f;
}

.announce-bar button {
  background: transparent;
  border: none;
  cursor: pointer;
  overflow: hidden;
  width: 100%;
  height: 95%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.announce-bar button:hover {
  background-color: rgba(51,51,51,0.05);
}

.announce-text {
  font-family: "Work Sans", sans-serif;
  font-size: 1.15rem;
  font-weight: 550;
  color: white;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
  width: 100%;
  transform-origin: 50% 0;
  animation: flipIn 1.25s ease-in-out;
}

@keyframes flipIn {
  from {
      transform: rotateX(90deg);
      opacity: 0.2;
  }
  to {
      transform: rotateX(0deg);
      opacity: 1;
  }
}
