.footer {
    background: linear-gradient(to right, #252424, #716f6f);
    color: #ffffff;
    position: absolute;
    bottom: 0;
    width: 100%;
    border-radius: 10px 10px 0 0;
  }
.footer-text{
    flex: 1;
    text-align: center;
    font-size: 13px;
    line-height: 0.8;
    font-weight: bold;
    white-space: pre;
  }

.footer-text-copyright{
    text-align: center;
    font-size: 10px;
    line-height: 1;
}
.social-icons {
  margin-bottom: 5px;  /* 根據需要調整 */
}

.social-icons a {
  margin: 0 10px;
  color: #a4b0e1; /* 黑色為例，您可以選擇其他顏色 */
  transition: color 0.3s;
}

.social-icons a:hover {
  color: #58b053; /* 深藍色為例，這是當滑過圖標時的顏色 */
}