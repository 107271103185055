/* 主容器樣式 */
.timeline-container {
    position: relative;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 50px 0;
}

/* 單一事件的基礎樣式 */
.timeline-item {
    display: flex;
    justify-content: flex-end;
    padding: 20px 0;
    position: relative;
    width: 100%;
}

/* 偶數事件內容在左側 */
.timeline-item:nth-child(even) {
    justify-content: flex-start;
}

/* 事件內容 */
.timeline-item-content {
    background: #f9fafc; /* 更淺的灰色，提供清新的感覺 */
    border-radius: 15px; /* 增加圓角大小，提供更現代的外觀 */
    padding: 25px; /* 略微增加內間距 */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* 陰影更柔和 */
    width: 50%;
    transition: transform 0.3s; /* 交互反饋效果 */
}


/* 標籤樣式 */
.timeline-item-content span.tag {
    background: #e5e9f2; /* 更淺的背景，與內容區背景形成對比 */
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: bold;
}

/* 時間樣式 */
.timeline-item-content time {
    font-size: 15px; /* 略微增加字體大小 */
    font-weight: bold;
    color: #333; /* 深色字體，增強對比 */
}

/* 描述段落 */
.timeline-item-content p {
    margin: 15px 0; /* 增加上下間距 */
    line-height: 1.6; /* 增加行高，提高可讀性 */
}

/* 連結樣式 */
.timeline-item-content a {
    padding: 8px 12px; /* 增加間距 */
    border: 1px solid #dfe4ed; /* 更淺的邊框顏色 */
    border-radius: 8px; /* 增加圓角 */
    display: inline-block;
    margin-top: 15px; /* 增加上間距 */
    transition: all 0.3s;
    text-decoration: none; /* 去除下劃線 */
    color: inherit; /* 繼承文字顏色 */
}

.timeline-item-content a:hover {
    border-color: #75abe4;
    color: #2073cc;
    background: #f1f5fb; /* 淺藍色背景 */
}

/* 中心點樣式 */
span.circle {
    background: #75abe4;
    border: 3px solid #fff;
    border-radius: 50%;
    width: 24px; /* 略微增大 */
    height: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    z-index: 2;
}

/* 中心時間軸 */
.timeline-container::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    border-left: 4px solid #75abe4;
    z-index: 1;
}

/* 清除浮動 */
.timeline-container::after {
    content: '';
    display: table;
    clear: both;
}

/* Center-align the entire div */
.additional-content {
    text-align: center;
    margin-top: 20px; /* Adjust the margin as needed */
}

/* Add a blue underline around the text */
.additional-content p {
    position: relative;
    display: inline-block;
    padding-bottom: 5px; /* Adjust the padding as needed */
    font-weight: bold;
}

.additional-content p::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px; /* Adjust the underline height as needed */
    background-color: #6A6AFF; /* Blue color for the underline */
}
