
.nav-item {
  color: #83818c;
  padding: 20px;
  text-decoration: none;
  transition: 0.3s;
  margin: 0 6px;
  z-index: 1;
  font-family: "DM Sans", sans-serif;
  font-weight: 500;
  position: relative;
}
.nav-item:before {
  content: "";
  position: absolute;
  bottom: -6px;
  left: 0;
  width: 100%;
  height: 5px;
  opacity: 0;
  transition: 0.3s;
}

.nav-item:not(.is-active):hover:before {
  opacity: 1;
  bottom: 0;
}

.nav-item:not(.is-active):hover {
  color: #333;
}

.nav-indicator {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  transition: 0.4s;
  height: 5px;
  z-index: 1;
}

@media (max-width: 580px) {
  .nav {
    overflow: auto;
  }
}

.not-clickable {
    pointer-events: none;
  }
  
.navbar-logo {
    height: 28px;   /* 根據需要調整 */
    width: 28px;    /* 根據需要調整 */
  }

.calligraphy-font {
    font-family: 'Dancing Script', cursive;
    font-weight: bolder;
  }